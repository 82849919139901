let resumeData = {
  imagebaseurl: "http://localhost:3001/",
  name: "Rukshan Uddin",
  role: "Full-Stack Developer",
  github: "https://github.com/rukshanuddin",
  twitter: "https://twitter.com/rukshanuddin",
  blog: "https://rukshanuddin.github.io/",
  dev: "https://dev.to/rukshanuddin",
  linkedin: "https://www.linkedin.com/in/rukshan-uddin/",
  medium: "https://medium.com/@rukshan.uddin",
  stackoverflow: "https://stackoverflow.com/users/12884280/rukshan-uddin",
  youtube: "https://www.youtube.com/channel/UCisZve6wSoXUqg64mGwQ3jg/videos",
  email: "uddinrukshan@gmail.com",
  //  "rukshan.uddin@gmail.com",
  addressLine1: "243 Fortuna Dr",
  addressLine2: "Palm Beach Gardens, FL 33410 US",
  phone: "+1 347 221 7972",
  whatsapp: "https://wa.me/13472217972",
  resume:
    "https://drive.google.com/file/d/1Gad33uZCzaxxu5bppKVHokfX8emLQcUg/view?usp=sharing",
  // "https://drive.google.com/file/d/1HeMB5UmKEuttvreUQ9vvAp72dot_szX7/view?usp=sharing",
  school: [
    // {
    //   name: "Woz U",
    //   program: "Application Development - Java Intensive - Infosys",
    //   link: "https://woz-u.com/enterprise/apprenticeship/",
    //   info:
    //     "One of the largest technology apprenticeship programs in the United States, registered and approved by the United States Department of Labor. Training included HTML, CSS, Javascript, Front-end and Server-side technologies with a focus on Core Java concepts to impart day-one ready skills",
    //   completion: "March 2020",
    //   certificate: {
    //     link:
    //       "https://drive.google.com/file/d/1tJIsmWz4SK3-EWZ-yju3TZAm3RX5R1wA/view?usp=sharing",
    //     text: "Certificate of Completion",
    //   },
    // },
    // {
    //   name: "Flatiron School",
    //   program: "Software Engineering Intensive",
    //   link: "http://www.flatironschool.com",
    //   info:
    //     "Ranked #1 coding bootcamp by Course Report, 2019-2020. Full-time software engineering program, covering programming fundamentals, web frameworks, Javascript and front-end frameworks.",
    //   completion: "August 2020",
    //   certificate: {
    //     link:
    //       "https://drive.google.com/file/d/1zaZ_GXhiZZeXcItE8wJJeMzo9iIjki7E/view?usp=sharing",
    //     text: "Certificate of Completion",
    //   },
    // },
    {
      name: "Palm Beach State College",
      program: "Associates in Arts",
      link: "http://www.palmbeachstate.edu",
      info: "Completed an Associate in Arts with a focus in Business Administration. Member Phi Theta Kappa honor society.",
      completion: "May 2009",
      certificate: {
        link: "",
        text: "",
      },
    },
    {
      name: "University of Florida",
      link: "http://www.ufl.edu",
      info: "Expect to complete program Spring 2024",
      completion: "Expected 2024",
      program: "Bachelor's of Science in Computer Science",
      certificate: {
        link: "",
        text: "",
      },
    },
  ],
  work: [
    // {
    //   name: "University of Texas at Austin",
    //   link: "https://techbootcamps.utexas.edu/coding/",
    //   position: "Teaching Assistant",
    //   dates: "Nov 2020 - Present",
    //   info:
    //     "Support the Instructor during lectures and educational program specific activities. Lead weekly office hours on one weekend day for 2 hours.",
    // },
    // {
    //   name: "Trilogy Education Services",
    //   link: "https://www.trilogyed.com/",
    //   position: "Central Grader",
    //   dates: "Sep 2020 - Present",
    //   info:
    //     "Code reviewer for student repositories hosted on GitHub. Graded student deployments on Heroku, GitHub Pages or Netlify.",
    // },
    // {
    //   name: "Appen Global",
    //   link: "http://www.appen.com",
    //   position: "Internet Analyst",
    //   dates: "Dec 2019 - Present",
    //   info:
    //     "Involved with analyzing data for top social media companies. Help train AI systems for voice recognition as a fully remote independent contractor. Have worked on multiple data collection projects.",
    // },
    // {
    //   name: "Hena Perfume & Fashion Accessories",
    //   link: "/#",
    //   position: "Owner/Operator",
    //   dates: "August 2016 - April 2017",
    //   info:
    //     "Managed inventory for a small gift shop and curated inventory as demands changed to deal with customer needs.",
    // },
    // {
    //   name: "YAK NYC",
    //   link: "https://vimeo.com/76736506",
    //   position: "Partner",
    //   dates: "August 2014 - December 2014",
    //   info:
    //     "Created innovative guerilla ad campaigns. Managed and designed website/physical inventory. Procured wholesale goods for conversion to house designs. ",
    // },
    {
      name: "YAK NYC",
      link: "https://vimeo.com/76736506",
      position: "Partner",
      dates: "August 2014 - December 2014",
      info: "Created innovative guerilla ad campaigns. Managed and designed website/physical inventory. Procured wholesale goods for conversion to house designs. ",
    },
    {
      name: "YAK NYC",
      link: "https://vimeo.com/76736506",
      position: "Partner",
      dates: "August 2014 - December 2014",
      info: "Created innovative guerilla ad campaigns. Managed and designed website/physical inventory. Procured wholesale goods for conversion to house designs. ",
    },
    {
      name: "YAK NYC",
      link: "https://vimeo.com/76736506",
      position: "Partner",
      dates: "August 2014 - December 2014",
      info: "Created innovative guerilla ad campaigns. Managed and designed website/physical inventory. Procured wholesale goods for conversion to house designs. ",
    },
  ],
  skills: {
    info: "Full Stack Developer | React.js | Android | Node.js | Javascript | Ruby on Rails | Java | Kotlin",
  },
  projects: [
    {
      name: "final_backend",
      description:
        "+ Utilized Ruby on Rails to create a JSON API to serve as the backend of the application\n\n+ Integrated JWT to authenticate client-side on the backend\n\n+ Used async Redux/React on the front-end to render components and to send HTTP requests to backend\n",
      url: "",
      displayName: "final_backend",
      summary:
        'Web app version of an unofficial Flatiron School resource, the "BALOCK," a spreadsheet containing links to various resources regarding software development and tech in general.',
      primaryLanguage: "Ruby",
      languages: ["Ruby", "HTML"],
      libraries: ["Ruby on Rails"],
      githubUrl: "https://github.com/rukshanuddin/final_backend",
      repositoryUrl: "https://github.com/rukshanuddin/final_backend",
      images: [],
      videos: [],
    },
    {
      name: "final_frontend",
      description:
        "+ Utilized Ruby on Rails to create a JSON API to serve as the backend of the application\n+ Integrated JWT to authenticate client-side on the backend\n+ Used async Redux/React on the front-end to render components and to send GET/POST/DELETE requests to backend",
      url: "",
      displayName: "final_frontend",
      website: "",
      summary:
        'Web app version of an unofficial Flatiron School resource, the "BALOCK," a spreadsheet containing links to various resources regarding software development and tech in general.',
      primaryLanguage: "JavaScript",
      languages: ["HTML", "JavaScript", "CSS"],
      libraries: ["React"],
      githubUrl: "https://github.com/rukshanuddin/final_frontend",
      repositoryUrl: "https://github.com/rukshanuddin/final_frontend",

      images: [],
      videos: [],
    },
    {
      name: "dictionary",
      description:
        "Includes the capability to add other dictionary APIs through service objects with ease.",
      url: "https://eerie-mummy-44381.herokuapp.com/",
      displayName: "Dictionary",
      website: "https://eerie-mummy-44381.herokuapp.com/",
      summary: "A Ruby on Rails app built to utilize the Oxford Dictionary API",
      primaryLanguage: "Ruby",
      languages: ["ERB", "Ruby", "HTML", "JavaScript", "SCSS"],
      libraries: ["Heroku", "Bootstrap", "Rails"],
      githubUrl: "https://github.com/rukshanuddin/Dictionary",
      repositoryUrl: "https://github.com/rukshanuddin/Dictionary",

      images: [
        {
          resolutions: {
            micro: {
              url: "https://project-images.gitconnectedcontent.com/a6776f95-3b5d-4553-aa76-f19ea6bc9310-micro",
              size: 803,
              width: 32,
              height: 19,
            },
            thumbnail: {
              url: "https://project-images.gitconnectedcontent.com/a6776f95-3b5d-4553-aa76-f19ea6bc9310-thumbnail",
              size: 3668,
              width: 224,
              height: 131,
            },
            mobile: {
              url: "https://project-images.gitconnectedcontent.com/a6776f95-3b5d-4553-aa76-f19ea6bc9310-mobile",
              size: 10208,
              width: 600,
              height: 352,
            },
            desktop: {
              url: "https://project-images.gitconnectedcontent.com/a6776f95-3b5d-4553-aa76-f19ea6bc9310-desktop",
              size: 34171,
              width: 1200,
              height: 704,
            },
          },
        },
      ],
      videos: [],
    },
    {
      name: "rails-js-project",
      description:
        "+ Utilized Ruby on Rails to create a JSON API to serve as the backend of the application\n+ Integrated Twitter API to fetch tweets from Twitter in real-time\n+ Used Javascript on the front-end to render components on page and to send HTTP requests to backend",
      url: "",
      displayName: "rails-js-project",
      website: "",
      summary:
        "A single page application, that allows an end-user to collect tweets from Twitter and comment on them for personal use.",
      primaryLanguage: "Ruby",
      languages: ["HTML", "CSS", "JavaScript", "Ruby"],
      libraries: ["Ruby on Rails"],
      githubUrl: "https://github.com/rukshanuddin/rails-js-project",
      repositoryUrl: "https://github.com/rukshanuddin/rails-js-project",

      images: [],
      videos: [
        {
          url: "https://www.youtube.com/watch?v=oHdBWOe6Cpk",
          source: "youtube",
          sourceId: "oHdBWOe6Cpk",
        },
      ],
    },
    {
      name: "portfolio-db",
      description:
        "+ Used Ruby on Rails to create an application to handle database and render views for end user\n+ Implemented light Javascript for some styling tweaks\n+ Made use of Bootstrap for major layouts and styling",
      url: "",
      displayName: "portfolio-db",
      website: "",
      summary:
        "Web application for students to display portfolio projects and make comments on each others projects",
      primaryLanguage: "Ruby",
      languages: ["JavaScript", "CSS", "HTML", "Ruby"],
      libraries: ["Bootstrap", "Ruby on Rails"],
      githubUrl: "https://github.com/rukshanuddin/portfolio-db",
      repositoryUrl: "https://github.com/rukshanuddin/portfolio-db",
      images: [],
      videos: [
        {
          url: "https://www.youtube.com/watch?v=I6m2xsXSuLc&t=89s",
          source: "youtube",
          sourceId: "I6m2xsXSuLc",
        },
      ],
    },
    {
      name: "student-portfolio",
      description:
        "+ Employed the Corneal gem to help generate a simple Sinatra application\n+ Implemented a simple CRUD interface to deal with student projects\n+ Used Ruby, Sinatra and CSS to implement the views of the application",
      url: "",
      displayName: "student-portfolio",
      website: "",
      summary:
        "Application for students to track their projects for each Flatiron School module.",
      primaryLanguage: "Ruby",
      languages: ["Ruby", "CSS", "HTML"],
      libraries: ["Sinatra"],
      githubUrl: "https://github.com/rukshanuddin/student-portfolio",
      repositoryUrl: "https://github.com/rukshanuddin/student-portfolio",
      images: [],
      videos: [
        {
          url: "https://www.youtube.com/watch?v=8PS8ZPr5kD8",
          source: "youtube",
          sourceId: "8PS8ZPr5kD8",
        },
      ],
    },
  ],
};
export default resumeData;
