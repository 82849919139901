import React, { Component } from "react";
export default class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="about">
          <div className="row">
            <div className="three columns">
              <img className="profile-pic" src="images/profile.jpeg" alt="me" />
            </div>
            <div className="nine columns main-col">
              <h2>About Me</h2>
              <p>{this.props.aboutData.bio}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>{this.props.resumeData.name}</span>
                    <br />
                    <span>
                      {this.props.resumeData.addressLine1}
                      <br />
                      {this.props.resumeData.addressLine2}
                    </span>
                    <br />
                    <span>+1 561 666 7461</span>
                    <br />
                    {/* <span>
                      <a href={this.props.resumeData.whatsapp}>
                        WhatsApp: <i className="fab fa-whatsapp" />
                        {this.props.resumeData.phone}
                      </a>
                    </span> */}
                    <br />
                    <span>
                      <a href={"mailto:" + this.props.resumeData.email}>
                        {this.props.resumeData.email}
                      </a>
                    </span>
                  </p>
                </div>
                <div className="columns download">
                  <p>
                    <a href={this.props.resumeData.resume} className="button">
                      <i className="fa fa-download" />
                      Download Resume
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
