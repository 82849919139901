import React from "react";
import Header from "./components/Header";
import About from "./components/About";

import Footer from "./components/Footer";
import resumeData from "./resumeData";
import aboutData from "./aboutData";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Route
          exact
          path="/"
          render={(routeProps) => (
            <Header resumeData={resumeData} {...routeProps} />
          )}
        />
        <Route
          exact
          path="/about"
          render={(routeProps) => (
            <About aboutData={aboutData} resumeData={resumeData} {...routeProps}/>
          )}
        />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
